import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyMaskInputMode } from "ngx-currency";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from "primeng/button";
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
//import { SharedModule } from 'primeng/sha';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { BreadcrumbComponent } from "./shared/componentes/breadcrumb/breadcrumb.component";
import { CadastroComponent } from "./shared/componentes/cadastro/cadastro.component";
import { PesquisaAvancadaClienteComponent } from './shared/componentes/pesquisa-avancada-cliente/pesquisa-avancada-cliente.component';
import { AsInputNumberDirective } from "./shared/diretiva/as-input-number.directive";
import { AsInputTextDirective } from "./shared/diretiva/as-input-text.directive";
import { EventosService } from './shared/service/eventos.service';

import { NgxCurrencyModule } from "ngx-currency";

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};



@NgModule({
  declarations: [
    BreadcrumbComponent,    
    PesquisaAvancadaClienteComponent,    
    CadastroComponent,
    AsInputNumberDirective,
    AsInputTextDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    InputTextModule,
    InputSwitchModule,
    InputMaskModule,
    DropdownModule,
    AutoCompleteModule,
    ButtonModule,
    TableModule,
    //SharedModule,
    ProgressBarModule,
    ConfirmDialogModule,
    PasswordModule,
    InputTextareaModule,
    CheckboxModule,
    EditorModule,
    PanelModule,
    PaginatorModule,
    CalendarModule,
    DialogModule,
    ListboxModule,
    ToolbarModule,
    AccordionModule,
    TabViewModule,
    TreeModule,
    TreeTableModule,    
    GalleriaModule,
    FileUploadModule,    
    EditorModule,    
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    InputTextModule,
    InputSwitchModule,
    InputMaskModule,
    DropdownModule,
    AutoCompleteModule,
    ButtonModule,
    TableModule,
    //SharedModule,
    ConfirmDialogModule,
    PasswordModule,
    InputTextareaModule,
    CheckboxModule,
    EditorModule,
    PanelModule,
    ProgressBarModule,
    PaginatorModule,
    CalendarModule,
    DialogModule,
    ListboxModule,
    ToolbarModule,
    AccordionModule,
    TabViewModule,
    TreeModule,
    TreeTableModule,
    BreadcrumbComponent,
    PesquisaAvancadaClienteComponent,    
    CadastroComponent,
    AsInputNumberDirective,
    AsInputTextDirective,
    GalleriaModule,
    FileUploadModule,    
    EditorModule
  ],
  providers: [EventosService],
  bootstrap: []

})
export class AraguaiaSharedModule {

}



