import { FormGroup } from "@angular/forms";
import { KEY_CODE } from "../constantes/key-codes";
import { Injectable } from "@angular/core";

@Injectable()
export class EventosService {

    constructor(
    ){}
    proxElementWithTab(event,target){
        /* KeyCode = TAB */
        if(event.shiftKey == false && event.keyCode == KEY_CODE.TECLA_TAB){
            document.querySelector(target).focus();
            event.preventDefault();
        }
    }

    prevElementWithTab(event,target){
        /* KeyCode = SHIFT + TAB */

        if(event.shiftKey == true && event.keyCode == KEY_CODE.TECLA_TAB){
            document.querySelector(target).focus();
            event.preventDefault();
        }
    }

    disabledElements(tipo:any){
        let elements = document.querySelectorAll(tipo);
        let quant = elements.length;
        if(quant > 0){
            let i = 0;
            while( quant > 0){
                elements[i].disabled = true;
                i++;
                quant--;
            }
            this.enabledElements(".btnVoltar");
            this.enabledElements(".ng-tns-c1-0"); //botões de modal [sair: sim,não]
        }
    }

    enabledElements(tipo:any){
        let elements = document.querySelectorAll(tipo);
        let quant = elements.length;
        if(quant > 0){
            let i = 0;
            while( quant > 0){
                elements[i].disabled = false;
                i++;
                quant--;
            }
        }
    }

    elementFocus(event,target){
        if(event != null) event.preventDefault();
        setTimeout(() =>{
            document.querySelector(target).focus();
        },100); 
    }  

    encolherToggle(){
        /*
        let toggle : any = '.sidebar-toggle';
        let collapse : any = '.sidebar-collapse';
        let largura =  window.innerWidth;
        if(largura > 767) { 
            let menu = document.querySelector(collapse);
            if(!menu){
                document.querySelector(toggle).click();
            }
        }
        */
    }

    /**
     * Realiza a mudança de página automaticamente conforme for adicionando itens no DataTable 
     * @param totalItens recebe o total de itens do DataTable: MasterDetailService.list.length
     * @param limiteItens recebe o valor máximo de páginas que deve conter até que seja realizado a paginação: Padrão = 8
    */
    paginarDataTable(totalItens:number, limiteItens:number){
        if(totalItens > limiteItens){
            let proxPage = document.querySelector('.ui-paginator-last') as HTMLElement;
            setTimeout(() =>{
                proxPage.click();
            },200); 
        }
    }

}