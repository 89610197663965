import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { ClienteService } from '../../../administrativo/favorecido/cliente.service';
import { FavorecidoService } from '../../../administrativo/favorecido/favorecido.service';
import { KEY_CODE } from '../../constantes/key-codes';
import { QtdeRegistrosDataTable } from '../../constantes/qtde-registros-data-table';
import { TipoStatus } from '../../constantes/tipo-status';
import { SearchParams } from '../../service/generic.service';

@Component({
    selector: 'p-tabView-pesquisa-avancada-cliente',
    styleUrls: ['./pesquisa-avancada-cliente.component.css'],
    templateUrl: './pesquisa-avancada-cliente.component.html'
})
export class PesquisaAvancadaClienteComponent {
    public pesquisaFormCliente :FormGroup;
    public searchCliente : SearchParams = new SearchParams();
    public navigation : boolean = false;
    public quantidadePesquisa : number = 0;
    public selectedDefault = [];
    public selectedCliente = Object();
    public selectedClienteAux = Object(); 
    public index : number;

    @Input()  tipoPesquisaCliente;
    @Output() retornoTipoPesquisa = new EventEmitter();
    @Output() retornoClienteSelecionado = new EventEmitter();

    constructor(    public fb: FormBuilder,
                    public _clienteService: ClienteService,
                    public _fornecedorService: FavorecidoService
    ) {
        this.buildPesquisaCliente();
    }

    buildPesquisaCliente(): void {
        this.pesquisaFormCliente = this.fb.group({
            'id':[''],
            'pesquisaCliente': [''],
            'clienteId':[''],
            'nomeFantasia': [''],
            'razaoSocial': ['']
        });
    }

    loadClientes(event: LazyLoadEvent) {
        //Calcula a página atual
        let page = 0; 
        this.quantidadePesquisa++;
        if (event.first > 0) {
            page = event.first / event.rows;
        }
        
        this.searchCliente.page = page;
        this.searchCliente.size = QtdeRegistrosDataTable.QTDE;
        this.searchCliente.filters["pesquisa"];
        this.searchCliente.filters['status'] = TipoStatus.ATIVO;
        this.searchCliente.sorting[event.sortField] = event.sortOrder == -1 ? 'desc' : 'asc';

        if(this.tipoPesquisaCliente == "cliente"){
            this._clienteService.listarAC(this.searchCliente);
        }else{
            this.searchCliente.filters['tipoCliente'] = false;        
            this.searchCliente.filters['tipoFornecedor'] = true; 
            this._fornecedorService.list(this.searchCliente);
        }
        
        if(page >= 0){
            
            //Fica ouvindo o resultado da seleção do Produto Default
            var interval = setInterval(() =>{
                if(this.tipoPesquisaCliente == "cliente"){
                    this.selectedDefault = this._clienteService.pageResult.content;
                }else{
                    this.selectedDefault = this._fornecedorService.pageResult.content;
                }
                if(this.selectedDefault.length > 0 ){
                    clearInterval(interval);
                    // Set default row selection in first row DataTable Produtos
                    this.selectedCliente = this.selectedDefault['0'];
                    this.index = 0;
                    this.selectedFirstRow();
                }
            },600); 
        }
    }

    onPesquisaCliente(event) {
        /* Event diferente de SHIFT, TAB e SHIFT + TAB */
        if(event.keyCode != KEY_CODE.TECLA_SHIFT){
            if(event.keyCode != KEY_CODE.TECLA_TAB && event.keyCode != KEY_CODE.TECLA_SETA_PARA_BAIXO && event.keyCode != KEY_CODE.TECLA_ENTER){
                if(event.shiftKey != true && event.keyCode != KEY_CODE.TECLA_TAB){
                    this.navigation = false;
                    this.loadClientes(event); 
                }
            }else{
                if(event.keyCode == KEY_CODE.TECLA_TAB){
                    let table:any = '.selected-line-cliente';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO){
                    let table:any = '.selected-line-cliente';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if(event.keyCode == KEY_CODE.TECLA_ENTER){
                    let table:any = '.selected-line-cliente';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    this.onSelectedCliente(event);
                    event.preventDefault();
                }
            }
        }
    }

    onClickCliente(event){
        let button;
        let alvo = event.target;
        let buttonSelected;
        this.selectedCliente = this.selectedClienteAux['0'];

        switch(alvo.nodeName){
            case 'BUTTON':

                button = alvo.parentElement;
                button.click();

            break;
            case 'I':

                button = alvo.parentNode;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line-cliente");
                if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent-cliente selected-line-cliente";
            break;
            case 'DIV':

                button = alvo.parentNode.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line-cliente");
                if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent-cliente selected-line-cliente";
            break;
            case 'TD' :

                button = alvo.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line-cliente");
                if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent-cliente selected-line-cliente";
            break;
            case 'SPAN' :

                button = alvo.parentNode.parentElement.firstElementChild.lastElementChild;
                if(button.nodeName == 'SPAN') button = alvo.parentNode.parentNode.parentElement.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line-cliente");
                if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent-cliente selected-line-cliente";
            break;
            case 'P-TABLE':

                if(alvo.firstElementChild.firstElementChild.firstElementChild != null){
                    //Primeiro clique : O evento possui a navegação indicada
                    button = alvo.firstElementChild.firstElementChild.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                    
                }else{
                    //Segundo clique em diante : O evento não possui a primeira navegação indicada e sim esta
                    button = alvo.firstElementChild.firstElementChild.nextElementSibling.nextElementSibling.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                }
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                button.className = "btn-transparent-cliente selected-line-cliente";
            break;
            default:

        }
    }

    onKeydownCliente(event) {
        let buttonSelected;
        let proxButton;
        let prevButton;

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO) {
            event.preventDefault();
            if (document.getElementById('irmao')){
                if(event.target.parentElement.parentElement.nextElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-cliente");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent-cliente selected-line-cliente";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }        
            }else{
                if(event.target.parentElement.parentElement.nextElementSibling){
                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-cliente");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent-cliente selected-line-cliente";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            } 
        }

        /* KeyCode = seta para a cima */
        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_CIMA) {
            event.preventDefault();
            if (document.getElementById('irmao')){                              
                if(event.target.parentElement.parentElement.previousElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-cliente");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent-cliente selected-line-cliente";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
             
            }else{
                if(event.target.parentElement.parentElement){
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-cliente");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent-cliente selected-line-cliente";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }      
        }

        /* KeyCode = Shift + TAB */
        if(event.shiftKey == true && event.keyCode == KEY_CODE.TECLA_TAB){
            event.preventDefault();
            if (document.getElementById('irmao')){                              
                if(event.target.parentElement.parentElement.previousElementSibling){
                   
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-cliente");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent-cliente selected-line-cliente";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }else{
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";
                }
            }else{
                if(event.target.parentElement.parentElement.previousElementSibling){
                   
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-cliente");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent-cliente selected-line-cliente";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }     
            return;
        }
        
        /* KeyCode = TAB */
        if(event.keyCode === KEY_CODE.TECLA_TAB){
            event.preventDefault();
            if (document.getElementById('irmao')){
                if(event.target.parentElement.parentElement.nextElementSibling){

                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-cliente");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent-cliente selected-line-cliente";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }else{
                if(event.target.parentElement.parentElement.nextElementSibling){ 

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-cliente");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-cliente';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent-cliente selected-line-cliente";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
             }
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_DIREITA){
            let prox = document.getElementsByClassName('ui-paginator-next');
            prox[4].id = 'proxCliente';
            document.getElementById('proxCliente').click();
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_ESQUERDA){
            let prev = document.getElementsByClassName('ui-paginator-prev');
            prev[4].id = 'prevCliente';
            document.getElementById('prevCliente').click();
        }

        /* Selecionar produto */
        if(event.keyCode == KEY_CODE.TECLA_ENTER){
            this.onSelectedCliente(event);
        }
    }

    //EVENT EMITTER
    onSelectedCliente(event):void{
        this.retornoTipoPesquisa.emit({pesquisa:true});
        this.selectedCliente = this.selectedClienteAux['0'];
        this.retornoClienteSelecionado.emit(this.selectedCliente);
    }

    //EVENT EMITTER
    retornarPesquisaSimples(){
        this.retornoTipoPesquisa.emit({pesquisa:false});
    }

    selectedFirstRow(){
        let arr = document.getElementsByClassName('btn-transparent-cliente');
        arr[0].className = "btn-transparent-cliente selected-line-cliente";
        arr[0].id = "primeiro-item-cliente";
        document.getElementById("primeiro-item-cliente").click();
        document.getElementById("inputPesquisaCliente").focus();
    }

}