import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { DuplicataReceber } from '../financeiro/duplicata-receber/duplicata-receber.model';
import { DuplicataReceberService } from '../financeiro/duplicata-receber/duplicata-receber.service';
import { ParcelaReceberService } from '../financeiro/duplicata-receber/parcela-receber.service';
import { AuthService } from '../shared/auth/auth.service';
import { GlobalsVariablesService } from '../shared/service/global-variables.service';
import { Relatorio } from '../shared/util/relatorio.model';
import { Util } from '../utilitarios/util';
import { Dashboard } from './dashboard.model';
import { DashboardService } from './dashboard.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    usuarioPermissao;
    public dashboard = new Dashboard();
    public valorVendas: Number;
    public valorCompras: Number;
    public valorContasAPagar: Number;
    public valorContasAReceber: Number;
    public vendasAnual;
    public vendasMensal;
    public compras;
    public receberPagar;
    public financeiro;
    private lojaId;
    public filtro: FormGroup;
    public periodo = new Array();
    public periodoFormatado = new Array();
    public pt_BR: any;
    public version: string = environment.version;
    public duplicatas: Array<DuplicataReceber> = [];
    private modelRelatorio: Relatorio = new Relatorio();

    constructor(        
        private router: Router,
        private _dashboardService: DashboardService,
        private fb: FormBuilder,
        public _parcelaReceberService: ParcelaReceberService,
        public _duplicataReceberService: DuplicataReceberService,
        public authService: AuthService,
        public cookieService: CookieService,
        public _confirmationService: ConfirmationService,
        public globalsVariablesService: GlobalsVariablesService) {
        this.buildForm();
    }



    ngOnInit() {
        this.pt_BR = Util.traducaoDataCalendar();

        //PARA PREENCHIMENTO DO P-CALENDAR COM O PERÍODO
        let dataVigente = new Date();
        let dataInicio = new Date(dataVigente.getFullYear(), dataVigente.getMonth(), 1);
        this.periodo.push(dataInicio);
        let dataFim = new Date(dataVigente.getFullYear(), dataVigente.getMonth() + 1, 0);
        this.periodo.push(dataFim);
        this.filtro.get('periodo').setValue(this.periodo);

        //PARA PESQUISA DO PERÍODO
        this.periodoFormatado.push(Util.formatarData(this.periodo[0], '-'));
        this.periodoFormatado.push(Util.formatarData(this.periodo[1], '-'));


        this.valorCompras = 0.00;
        this.valorVendas = 0.00;
        this.valorContasAPagar = 0.00;
        this.valorContasAReceber = 0.00;
        this.atualizarDados();
        this.buscarDuplicatas();
        // this.reload();
    }

    buildForm(): void {
        this.filtro = this.fb.group({
            'periodo': ['']
        });
    }

    public buscarDuplicatas(): void {
        let interval = setInterval(() => {
            if (this.globalsVariablesService.getUsuarioLogado.favorecidoId != null
                && this.globalsVariablesService.getUsuarioLogado.favorecidoId != undefined) {

                clearInterval(interval);

                this._parcelaReceberService.listUltimasSeisDuplicatasAbertas(this.globalsVariablesService.getUsuarioLogado.favorecidoId)
                    .then((res) => {

                        this.duplicatas = res as any;
                        console.log(this.duplicatas)
                        console.log(res)
                    });
            }

        }, 300)
    }

    public logout() {
        this._confirmationService.confirm({
          message: 'Tem certeza que deseja sair do sistema ?',
          header: 'MR7 Church',
          icon: 'fa fa-power-off',
          accept: () => {
            this.resetSessao();
            this.authService.logout();
          }
        });
      }

      private resetSessao() {
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem("PagesActive", "1");
        this.cookieService.delete('user');
        this.globalsVariablesService.blockedGlobalsVariables();
        this.router.navigate(['/login']);
      }



    mostrar() {
        window.open(this.modelRelatorio.nome, "_blank");
    }

    onSelectDate() {
        this.periodo = new Array();
        this.periodoFormatado = new Array();
        this.periodo = this.filtro.get('periodo').value;

        if (this.periodo[1] != null) {
            //SE SELECIONADO AS 2 DATAS

            this.periodoFormatado.push(Util.formatarData(this.periodo[0], '-'));
            this.periodoFormatado.push(Util.formatarData(this.periodo[1], '-'));
            console.log("Selecionado as 2 Datas: \n", this.periodoFormatado);
            this.atualizarDados();
        }
    }

    atualizarDados() {
        var interval = setInterval(() => {
            this.lojaId = this.globalsVariablesService.getUsuarioLogado.lojaId;
            if (this.lojaId != null) {
                clearInterval(interval);
                // Foi recuperado totalmente as permissões
                this._dashboardService.totalVendasRealizadas(this.lojaId, this.periodoFormatado).then(res => {
                    this.dashboard = res;
                    this.valorVendas = this.dashboard.valorVendas;
                    this.valorCompras = this.dashboard.valorCompras;
                    this.valorContasAPagar = this.dashboard.valorDuplicatasPagar;
                    this.valorContasAReceber = this.dashboard.valorDuplicatasReceber;

                });
            }
        }, 600);
    }


    reload(): void {
        let acesso = localStorage.getItem("primeiroAcesso");

        if (acesso == 'true') {
            window.location.reload();
            localStorage.removeItem("primeiroAcesso")
        }
        else
            return;

    }

    navegarMembro(): void{
        this.router.navigate(['/membresia/membro/new']);
    }
}

