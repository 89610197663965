import { GenericModel } from '../../shared/model/generic.model';
import { CFOPImportacaoCompra } from './cfop-importacao-compra.model';
import { CSTImportacaoCompra } from './cst-importacao-compra.model';
import { SetupImagem } from './setup-imagem.model';

export class Setup extends GenericModel {
  public descricao:string;
    public tipoObjeto: string;
    public tipoPreco: string;
    public tipoTelaFavorecido: String;
    public nomeImagem: string;
    public caminhoImagem: string;
    public lojaPrecoId: number;
    public lojaPrecoPessoaNomeFantasia: string;
    public tamanhoImagem:number;
    public status: string = '';
    public ordemImpressaoPedido: string;
    public ordemImpressaoSeparacao: string;
    public tipoImpressora: string;
    public validarCpfCnpj:boolean;
    public precoDiferenciado:boolean;
    public vendaId:number;
    public vendaClientePadrao:number;
    public vendaClientePadraoNome:string;
    public vendaTipoCodigoProduto:number;
    public vendaTipoServico:boolean;
    public vendaTipoProduto:boolean;
    public vendaTransportadoraPadrao:number;
    public vendaTransportadoraPadraoNome:string;
    public vendaOperacaoPadrao: number;
    public vendaOperacaoPadraoNome: string;
    public vendaAtacarejo: String;
    public vendaQtdeAtacarejo: number;
    public vendaListaPrecoVarejoId: number;
    public vendaListaPrecoVarejoDescricao: string;
    public vendaListaPrecoAtacadoId: number;
    public vendaListaPrecoAtacadoDescricao: string;
    public operacaoPadraoId: number;
    public operacaoPadraoDescricao: string;
    public vendaDescontoItemPdv:boolean;
    public vendaDescontoItemPedido:boolean;
    public financeiroId:number;
    public financeiroPlanoContaPDV:number;
    public financeiroPlanoContaDescricaoPDV:string;
    public financeiroPlanoContaEstorno:number;
    public financeiroPlanoContaDescricaoEstorno:string;
    public financeiroPlanoContaCliente:number;
    public financeiroPlanoContaDescricaoCliente:string;
    public financeiroPlanoContaFornecedor:number;
    public financeiroPlanoContaDescricaoFornecedor:string;
    public financeiroStatus:string;
    public setupImagens: Array<SetupImagem>;
    public cfopsImportacao: Array<CFOPImportacaoCompra>;
    public cstsImportacao: Array<CSTImportacaoCompra>;
    public formaPagamentoId: number;
    public formaPagamentoDescricao: string;
    public categoriaId: number;
    public categoriaDescricao: string = '';
    public marcaId: number;
    public marcaDescricao: string = '';
    public unidadeId: number;
    public unidadeDescricao: string = '';
    public grupoTributoProdutoId: number;
    public grupoTributoProdutoDescricao:string='';
    public situacaoClienteId: number;
    public situacaoClienteDescricao: string;
    public listaPrecoId: number;
    public listaPrecoDescricao: string;
    public percentualRentabilidade:number;
    public enderecoImpressora:string;
    public enderecoImpressoraSeparacao:string;
    public integracao:string;
    public impressaoLocal:string;
    public isCelula;
    public tipoSistema;

}
