import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
    

    private id: string;

    constructor(
        private _router: Router,        
        private fb: FormBuilder,
        private _route: ActivatedRoute) {
    }

    ngOnInit() {

      this._route.params.forEach((params: Params) => {
        this.id = String(+params['id']);
        console.log(this.id);
      });
       
    }

}

